import { Color, ColorGradient } from "@anderjason/color";
import { Point2, Rotation } from "@anderjason/geometry";
import { Receipt } from "@anderjason/observable";
import { NumberUtil, Percent } from "@anderjason/util";
import { ManagedCanvas, ScreenSize } from "@anderjason/web";
import { Actor } from "skytree";

export interface RotateAroundPointDemoProps {
  parentElement: HTMLElement;
}

interface RotatingPoint {
  targetPoint: Point2;
  currentPoint: Point2;
  degreesPerFrame: number;
  color: Color;
  pulse: number;
}

export class RotateAroundPointDemo extends Actor<RotateAroundPointDemoProps> {
  onActivate() {
    const trailCanvas = this.addActor(
      new ManagedCanvas({
        parentElement: this.props.parentElement,
        displaySize: ScreenSize.instance.availableSize,
        renderEveryFrame: true,
        keepPreviousRenders: true,
      })
    );
    
    const pointerCanvas = this.addActor(
      new ManagedCanvas({
        parentElement: this.props.parentElement,
        displaySize: ScreenSize.instance.availableSize,
        renderEveryFrame: true,
      })
    );
    pointerCanvas.element.style.position = "absolute";
    pointerCanvas.element.style.left = "0";
    pointerCanvas.element.style.top = "0";
    pointerCanvas.element.style.zIndex = "10";

    const gradient = ColorGradient.givenSteps([
      Color.givenHexString("#0099FF"),
      Color.givenHexString("#ff0099"),
    ]);

    let rotatingPoints: RotatingPoint[] = [];
    let currentCenter: Point2 = Point2.ofZero();
    let targetCenter: Point2;
    let frameNumber: number = 0;
    const dpi = window.devicePixelRatio || 1;

    const onPointerMove = (e: PointerEvent) => {
      targetCenter = Point2.givenXY(e.clientX * dpi, e.clientY * dpi);
    };

    document.addEventListener("pointermove", onPointerMove, { passive: true });

    this.cancelOnDeactivate(
      new Receipt(() => {
        document.removeEventListener("pointermove", onPointerMove);
      })
    );

    this.cancelOnDeactivate(
      trailCanvas.pixelSize.didChange.subscribe((size) => {
        if (size == null) {
          return;
        }

        const { width, height } = size;

        const pointCount = 90;
        rotatingPoints = [];

        for (let i = 0; i < pointCount; i++) {
          const speed = NumberUtil.randomNumberGivenRange(0, 1);
          const targetPoint = Point2.givenXY(
            NumberUtil.randomNumberGivenRange(0, width),
            NumberUtil.randomNumberGivenRange(0, height)
          );

          rotatingPoints.push({
            targetPoint,
            currentPoint: targetPoint,
            degreesPerFrame: NumberUtil.numberWithRangeMap(
              speed,
              0,
              1,
              0.05,
              0.2
            ),
            color: gradient.toHclInterpolatedColor(
              Percent.givenFraction(Math.random(), 1)
            ),
            pulse: speed * 200,
          });
        }

        targetCenter = Point2.givenXY(width / 2, height / 2);
        currentCenter = targetCenter;
      }, true)
    );

    const frameDuration = 42;

    this.cancelOnDeactivate(
      trailCanvas.addRenderer(0, (renderProps) => {
        const { context, pixelSize } = renderProps;
        const { width, height } = pixelSize;

        context.globalAlpha = NumberUtil.randomNumberGivenRange(2, 7) / 100;
        context.fillStyle = "#17161E";
        context.fillRect(0, 0, width, height);
        context.globalAlpha = 1;

        frameNumber += 1;
        currentCenter = currentCenter.withAddedVector(
          currentCenter.toVector(targetCenter).withMultipliedScalar(0.04)
        );

        rotatingPoints.forEach((rotatingPoint, idx) => {
          rotatingPoint.targetPoint =
            rotatingPoint.targetPoint.withRotationAroundPoint(
              currentCenter,
              Rotation.givenDegrees(rotatingPoint.degreesPerFrame)
            );

          const c = NumberUtil.numberWithRangeMap(
            Math.sin((frameNumber + rotatingPoint.pulse) / frameDuration),
            -1,
            1,
            0,
            1
          );

          const t = NumberUtil.numberWithRangeMap(
            Math.sin(
              ((frameNumber + rotatingPoint.pulse) / frameDuration) * 0.35
            ),
            -1,
            1,
            0.1,
            0.6
          );

          const vector = currentCenter
            .toVector(rotatingPoint.targetPoint)
            .withMultipliedScalar(t);

          const newTargetPoint = currentCenter.withAddedVector(vector);

          const newCurrentPoint = rotatingPoint.currentPoint.withAddedVector(
            rotatingPoint.currentPoint
              .toVector(newTargetPoint)
              .withMultipliedScalar(0.01)
          );
          rotatingPoint.currentPoint = newCurrentPoint;

          context.beginPath();
          const color = gradient.toHclInterpolatedColor(
            Percent.givenFraction(c, 1)
          );
          context.fillStyle = color.toHexString();
          context.moveTo(newCurrentPoint.x, newCurrentPoint.y);
          context.arc(newCurrentPoint.x, newCurrentPoint.y, 8, 0, 2 * Math.PI);
          context.fill();
        });
      })
    );

    this.cancelOnDeactivate(
      pointerCanvas.addRenderer(0, (renderProps) => {
        const { context, pixelSize } = renderProps;
        
        context.beginPath();
        context.fillStyle = "#FFFFFF";
        context.moveTo(currentCenter.x, currentCenter.y);
        context.arc(currentCenter.x, currentCenter.y, 10, 0, 2 * Math.PI);
        context.fill();
      })
    );
  }
}
